export const ATTRIBUTE_TYPE = {
  INPUT: 'input',
  DATE: 'date',
};

export const SESSION_KEY = 'transaction-flow-details';

export const BIZ_TYPE = [
  // 入库
  {
    label: '订单入库',
    value: 'ORDER_IN',
  }, {
    label: '调拨入库',
    value: 'ALLOT_IN',
  }, {
    label: '移库移位(入)',
    value: 'SHIFT_PARKING_IN',
  }, {
    label: '退货入库',
    value: 'CANCEL_IN',
  }, {
    label: '调整入库',
    value: 'ADJUST_IN',
  }, {
    label: '虚拟入库',
    value: 'VIRTUAL_IN',
  }, {
    label: '盘盈入库',
    value: 'INVENTORY_MORE',
  }, {
    label: '期初建账',
    value: 'INITIAL_ACCOUNT',
  }, {
    label: '总拣回库',
    value: 'TOTAL_PICK_UP',
  }, {
    label: '零担入库',
    value: 'TEMPORARY_IN',
  }, {
    label: '其它入库',
    value: 'OTHER_IT_IN',
  }, {
    label: '其他入库',
    value: 'OTHER_HE_IN',
  }, {
    label: '调拨入库(库内)',
    value: 'ALLOT_STORE_IN',
  }, {
    label: '客退入库',
    value: 'RETURNED',
  }, {
    label: '拒收入库',
    value: 'REJECTED',
  }, {
    label: '经由品入库',
    value: 'THROUGH',
  },
  // 出库
  {
    label: '订单出库',
    value: 'ORDER_OUT',
  }, {
    label: '调拨出库',
    value: 'ALLOT_OUT',
  }, {
    label: '移库移位(出)',
    value: 'SHIFT_PARKING_OUT',
  }, {
    label: '调整出库',
    value: 'ADJUST_OUT',
  }, {
    label: '盘亏出库',
    value: 'INVENTORY_LESS',
  }, {
    label: '销毁出库',
    value: 'DESTROY_OUT',
  }, {
    label: '退供出库',
    value: 'CANCEL_OUT',
  }, {
    label: '总拣下架',
    value: 'TOTAL_PICK_DOWN',
  }, {
    label: '零担出库',
    value: 'TEMPORARY_OUT',
  }, {
    label: '其它出库',
    value: 'OTHER_IT_OUT',
  }, {
    label: '其他出库',
    value: 'OTHER_HE_OUT',
  }, {
    label: '调拨出库(库内)',
    value: 'ALLOT_OUT_BOUND_IN',
  },
  // 拣货
  {
    label: '货主拣货',
    value: 'SHIPPER_PICK_GOODS',
  }, {
    label: '线路拣货',
    value: 'PATH_PICK_GOODS',
  },
  // FIXME 线上有这种数据,疑似openapi导入
  {
    label: '盘亏入库',
    value: 'INVENTORY_LOSS_MORE',
  },
  // WMS 2.0业务
  {
    label: '总拣移库',
    value: 'TOTAL_PICK_MOVE',
  },
  // WMS 2.0 盘点,多货时新增0库存
  {
    label: '盘点',
    value: 'COUNT',
  },
];
