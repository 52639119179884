import { FormType } from '@/constant/form';
import { DateTime } from '@/constant/tableConfig';
import { BIZ_TYPE } from './constant';

export const TABLECOLUMN = [{
  label: '序号',
  type: 'index',
  width: 50,
}, {
  label: '交易流水号',
  prop: 'id',
  minWidth: 140,
}, {
  label: '交易类型',
  prop: 'transactionType',
  minWidth: 80,
}, {
  label: '业务单据号',
  prop: 'bizNo',
  minWidth: 180,
}, {
  label: '业务类型',
  prop: 'bizType',
  minWidth: 100,
}, {
  label: '货主货品编码',
  prop: 'shipperGoodsCode',
  minWidth: 180,
}, {
  label: '货品名称',
  prop: 'goodsName',
  minWidth: 120,
}, {
  label: '货主',
  prop: 'shipperName',
  minWidth: 160,
}, {
  label: '批次号',
  prop: 'batchNo',
  minWidth: 140,
}, {
  label: '库位',
  prop: 'locationCode',
  minWidth: 130,
}, {
  label: '容器',
  prop: 'containerCode',
  minWidth: 100,
}, {
  label: 'LPN',
  prop: 'lpn',
  minWidth: 130,
}, {
  label: '货品质量',
  prop: 'goodsQuality',
  minWidth: 120,
}, {
  label: '换算率',
  prop: 'conversionRate',
  minWidth: 80,
}, {
  label: '默认单位',
  prop: 'defaultUnit',
  minWidth: 80,
}, {
  label: '交易数量(默认单位)',
  prop: 'defaultUnitChangeNum',
  minWidth: 160,
},
{
  label: '交易数量',
  prop: 'amount',
  minWidth: 80,
}, {
  label: '交易时间',
  prop: 'dataTime',
  ...DateTime,
},
];
export const serchFields = (GOODS_QUALITY) => [{
  label: '货品编码',
  prop: 'goodsCode',
  component: FormType.INPUT,
}, {
  label: '商品名称',
  prop: 'goodsName',
  component: FormType.INPUT,
}, {
  label: '货主',
  prop: 'shipperId',
}, {
  label: '库区',
  prop: 'warehouseAreaId',
}, {
  label: '库位',
  prop: 'locationCode',
  component: FormType.INPUT,
}, {
  label: '容器号',
  prop: 'containerCode',
  component: FormType.INPUT,
}, {
  label: '货品质量',
  prop: 'goodsQuality',
  component: FormType.SELECT,
  options: GOODS_QUALITY,
},
{
  label: '批次',
  prop: 'batchNo',
  component: FormType.INPUT,
}, {
  prop: 'labelSelect',
},
{
  label: '交易时间',
  prop: 'transactionTime',
  component: FormType.DATE_PICKER,
  componentAttrs: {
    type: 'datetimerange',
    default: [],
  },
}, {
  label: '业务类型',
  prop: 'bizType',
  component: FormType.SELECT,
  options: BIZ_TYPE,
  componentAttrs: {
    filterable: true,
  },
},
{
  label: '业务单据号',
  prop: 'bizNo',
  component: FormType.INPUT,
},
];
