<template>
  <pl-block>
    <PlForm
      v-model="searchQuery"
      mode="search"
      :fields="serchFields(GOODS_QUALITY)"
      @submit="handleQuery"
      @reset="resetQuery"
    >
      <template #shipperId>
        <nh-shipper-selector
          v-model:shipperId="searchQuery.shipperId"
        />
      </template>
      <template #warehouseAreaId>
        <el-select
          v-model="searchQuery.warehouseAreaId"
          filterable
          clearable
        >
          <el-option
            v-for="item in warehouseZonelist"
            :key="item.id"
            :label="item.zoneName"
            :value="item.id"
          />
        </el-select>
      </template>
      <template #labelSelect>
        <select-label
          v-model:labelSelectData="labelSelectData"
          :label-options="attributeCodes"
        />
      </template>
    </PlForm>
  </pl-block>
  <pl-block>
    <PlTable
      v-model:pagination="pagination"
      :loading="loading.getTableData"
      :data="tableData"
      :columns="TABLECOLUMN"
      :show-table-setting="true"
      @selection-change="handleSelectionChange"
      @update:pagination="handlePaginationChange"
    >
      <template #tableHeaderRight>
        <nh-button
          :loading="loading.handleExport"
          :track="{
            trackName: '在库管理/库存管理/交易流水/[导出]'
          }"
          @click="handleExport"
        >
          导出
        </nh-button>
      </template>
      <template #id="{row}">
        <nh-link
          :to="{}"
          :text="row.id"
          @click="handleDetails(row)"
        />
      </template>
    </PlTable>
  </pl-block>
  <TransactionDetails ref="transactionDialog" />
</template>
<script>
import loadingMixin from '@thales/loading';
import { SearchType } from '@/constant/form';
import InLibrary from '@/constant/pageNames/inLibrary';
import {
  TABLECOLUMN, serchFields,
} from './fileds';
import {
  stockHistoryPage, stockHistoryExport, warehouseZonePage, batchFieldList,
} from './api';
import { ATTRIBUTE_TYPE, SESSION_KEY } from './constant';
import selectLabel from '../../components/selectLabel.vue';
import TransactionDetails from './transaction-flow-details/index.vue';

export default {
  name: InLibrary.TRANSACTION_FLOW,
  components: {
    selectLabel,
    TransactionDetails,
  },
  mixins: [loadingMixin],
  data() {
    return {
      serchFields,
      TABLECOLUMN,
      SearchType,
      SESSION_KEY,
      tableData: [],
      searchQuery: {
        shipperId: null,
        batchAttrName: '',
        batchAttrValue: '',
      },
      loading: {
        getTableData: false,
        handleExport: false,
      },
      selectIds: [],
      attributeCodes: [],
      labelSelectData: {
        labelKey: '',
        labelValue: '',
      },
    };
  },
  computed: {
    GOODS_QUALITY() {
      return this.$dictionaryFiledMap.GOODS_QUALITY || [];
    },
  },
  created() {
    this.getWarehouseZonelist();
    this.loadBatchFieldList();
    this.getTableData();
  },
  methods: {
    async getWarehouseZonelist(word) {
      const resp = await warehouseZonePage({ page: 1, size: 1000 }, { zoneName: word || '' });
      this.warehouseZonelist = resp.records;
    },
    async loadBatchFieldList() {
      let result = await batchFieldList({});
      result = result || [];
      this.attributeCodes = result.map((item) => ({
        ...item,
        label: item.fieldName,
        value: item.fieldCode,
        type: item.component === ATTRIBUTE_TYPE.DATE
          ? this.SearchType.DATE_PICKER : this.SearchType.INPUT,
      }));
      this.labelSelectData.labelKey = this.attributeCodes[0].value;
    },
    formatBatchField(code, batchAttributes) {
      return batchAttributes ? batchAttributes[code] : '';
    },
    handleQuery() {
      this.pagination.page = 1;
      this.getTableData();
    },
    resetQuery() {
      this.labelSelectData.labelValue = '';
      this.labelSelectData.labelKey = this.attributeCodes[0].value;
      this.handleQuery();
    },
    async getTableData() {
      this.selectIds = [];
      const params = this.buildParams();
      const pagination = {
        page: this.pagination.page,
        size: this.pagination.size,
      };
      const resp = await stockHistoryPage(pagination, params);
      this.tableData = resp.records;
      this.pagination.total = resp.total;
    },
    handleSelectionChange(selection) {
      this.selectIds = selection.map((item) => item.id);
    },
    buildParams() {
      const [startTime, endTime] = this.searchQuery.transactionTime || [];
      this.searchQuery.batchAttrName = this.labelSelectData.labelKey;
      this.searchQuery.batchAttrValue = this.labelSelectData.labelValue;

      return {
        startTime,
        endTime,
        ...this.searchQuery,
      };
    },
    handlePaginationChange() {
      this.getTableData();
    },
    async handleExport() {
      const data = this.buildParams();
      if (this.selectIds.length > 0) {
        data.selectIds = this.selectIds;
      }
      await stockHistoryExport(data);
    },
    handleDetails(row) {
      this.$refs.transactionDialog.open(row);
    },
  },
};
</script>
<style lang="scss" scoped>
.batch-field {
  text-indent: 12px;
  padding: 4px 0;
}

</style>
