import { wmsPlusHttp } from '@/utils/http/index';

/**
 * @description:库存交易流水分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/15281
 */
export function stockHistoryPage(params, data) {
  return wmsPlusHttp.post('/warehouse_management_system/stock/stock_history/page', data, {
    params,
  });
}

/**
 * @description:库存交易流水导出
 * @api文档：https://yapi.ops.yunlizhi.cn/project/382/interface/api/15283
 */
export function stockHistoryExport(data) {
  return wmsPlusHttp.download('/warehouse_management_system/stock/stock_history/export', data, { fileName: '库存交易流水', extension: 'xlsx' });
}

/**
 * @description: 库区信息表 分页查询
 * @api文档：https://yapi.ops.yunlizhi.cn/project/388/interface/api/11164
 */

export function warehouseZonePage(params, data) {
  return wmsPlusHttp.post('/warehouse_foundation/zone/page', data, {
    params,
  });
}

/**
 * @description: 批次字段列表
 * @api文档：https://yapi.ops.yunlizhi.cn/project/385/interface/api/12622
 *
 */
export function batchFieldList(data) {
  return wmsPlusHttp.post('/warehouse_management_system/batch_field/list', data, {});
}
