<template>
  <div class="select-label-box">
    <div class="label-select">
      <el-select v-model="labelSelectDataProp.labelKey" @change="labelKeyChange">
        <el-option
          v-for="item in labelOptions"
          :key="item.value"
          :label="item.label"
          :value="item.value"
        />
      </el-select>
    </div>
    <div class="select-label-value">
      <template v-for="item in labelOptions" :key="item.value">
        <component
          :is="`el-${item.type}`"
          v-if="NoSlotComponents.includes(`el-${item.type}`)
            && item.value === labelSelectDataProp.labelKey"
          v-model="labelSelectDataProp.labelValue"
          v-bind="getFormFieldProps(item.type, item.props)"
        />
        <component
          :is="`el-${item.type}`"
          v-else-if="item.value === labelSelectDataProp.labelKey"
          v-model="labelSelectDataProp.labelValue"
          v-bind="getFormFieldProps(item.type, item.props)"
        >
          <template v-if="item.type === FieldsType.SELECT">
            <el-option
              v-for="option in item.options"
              :key="option.value"
              :label="option.label"
              :value="option.value"
            />
          </template>
        </component>
      </template>
    </div>
  </div>
</template>

<script>
import { ref, watchEffect } from 'vue';
import { NoSlotComponents } from '@/constant/form';

const FieldsType = {
  INPUT: 'input',
  SELECT: 'select',
};

export default {
  props: {
    labelOptions: {
      type: Array,
      default: () => [],
    },
    labelSelectData: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['update:labelSelectData'],
  setup(props, { emit }) {
    const labelSelectDataProp = ref(props.labelSelectData);
    watchEffect(() => {
      emit('update:labelSelectData', labelSelectDataProp.value);
    });
    const labelKeyChange = () => {
      labelSelectDataProp.value.labelValue = '';
      emit('update:labelSelectData', labelSelectDataProp.value);
    };
    return { labelSelectDataProp, labelKeyChange };
  },
  data() {
    return {
      FieldsType,
      NoSlotComponents,
      defaultFieldsProps: {
        'date-picker': {
          valueFormat: 'YYYY-MM-DD',
        },
      },
    };
  },
  methods: {
    getFormFieldProps(fieldType, props) {
      return { ...this.defaultFieldsProps[fieldType], ...props };
    },
  },
};
</script>
<style lang="scss" scoped>
.select-label-box {
  width: calc(100% + 95px);
  display: flex;
  margin-left: -95px;

  .label-select {
    width: 95px;
  }

  :deep(.el-date-editor.el-input,.el-date-editor.el-input__inner) {
    width: 100%;
  }

  .select-label-value {
    flex: 1;
  }
}
</style>
