<template>
  <nh-drawer
    v-model="drawerVisible"
    :before-close="handleClose"
    destroy-on-close
    title="交易流水详情"
    size="1111px"
  >
    <div class="content-box">
      <el-row>
        <el-col :span="8">
          <span>交易流水号：{{ fromData.id }}
            <el-tag type="success">{{ fromData.transactionType }}</el-tag>
          </span>
        </el-col>
        <el-col :span="8">
          <span>交易时间：{{ fromData.dataTime }}</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <span>业务单据号：{{ fromData.bizNo }}</span>
        </el-col>
        <el-col :span="8">
          <span>业务类型：{{ fromData.bizType }}</span>
        </el-col>
        <el-col :span="8">
          <span>库存ID：{{ fromData.stockId }}</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <span>货品编码：{{ fromData.goodsCode }}</span>
        </el-col>
        <el-col :span="8">
          <span>货品名称：{{ fromData.goodsName }}</span>
        </el-col>
        <el-col :span="8">
          <span>货主：{{ fromData.shipperName }}</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <span>批次号：{{ fromData.batchNo }}</span>
        </el-col>
        <el-col :span="8">
          <span>LPN：{{ fromData.lpn }}</span>
        </el-col>
        <el-col :span="8">
          <span>容器号：{{ fromData.containerCode }}</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <span>库区：{{ fromData.warehouseAreaName }}</span>
        </el-col>
        <el-col :span="8">
          <span>库位：{{ fromData.locationCode }}</span>
        </el-col>
        <el-col :span="8">
          <span>货品质量：{{ fromData.goodsQuality }}</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <span>交易前总库存：{{ fromData.beforeChangeTotalAmount }}</span>
        </el-col>
        <el-col :span="8">
          <span>交易前占用量：{{ fromData.beforeChangeLockedAmount }}</span>
        </el-col>
        <el-col :span="8">
          <span>交易前冻结量：{{ fromData.beforeChangeFrozenAmount }}</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <span>交易数量：{{ fromData.amount }}</span>
        </el-col>
        <el-col :span="8">
          <span>换算率：{{ fromData.conversionRate }}</span>
        </el-col>
      </el-row>
      <el-row>
        <el-col :span="8">
          <span>交易后总库存：{{ fromData.afterChangeTotalAmount }}</span>
        </el-col>
        <el-col :span="8">
          <span>交易后占用量：{{ fromData.afterChangeLockedAmount }}</span>
        </el-col>
        <el-col :span="8">
          <span>交易后冻结量：{{ fromData.afterChangeFrozenAmount }}</span>
        </el-col>
      </el-row>
    </div>
  </nh-drawer>
</template>

<script>
import { SESSION_KEY } from '../constant';

export default {
  data() {
    return {
      drawerVisible: false,
      SESSION_KEY,
      fromData: {},
    };
  },
  methods: {
    open(row) {
      this.drawerVisible = true;
      this.fromData = row;
    },
    handleClose() {
      this.fromData = {};
      this.drawerVisible = false;
    },
  },
};
</script>
<style lang="scss" scoped>
.content-box {
  margin-left: 20px;

  .el-row {
    margin: 20px 0;
  }
}
</style>
